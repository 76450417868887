import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { base_url } from '../../environment/url';
import axios from '../axiosApi/axiosApi';

import './ForgotPassword.css';

const passwordReset_debugThis = true;
const what = 'passwordReset';

const ForgotPassword = () => {
  const navigate = useNavigate();

  let timerInterval;
  const [email, setemail] = useState('');
  const [affiliation, setAffiliation] = useState('');
  const [countdown, setCountdown] = useState(3);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);

  const handlePasswordReset = async () => {
    const debugThis = false || passwordReset_debugThis;
    const msgPrefix = what + '.handlePasswordReset';

    if (debugThis) {
      console.log(msgPrefix, 'attempting password reset');
    }

    setSubmitButtonDisabled(true);

    if (debugThis) {
      console.log(msgPrefix, 'sending request');
    }

    await doPaswordResetRequest();

    const timerInterval = setInterval(() => {
      setCountdown((prevCount) => {
        if (prevCount === 1) {
          clearInterval(timerInterval);
          navigateBack();
        }
        return prevCount - 1;
      });
    }, 1000); // Update every 1 second
  };

  useEffect(() => {
    // Clean up interval on component unmount
    return () => clearInterval(timerInterval);
  }, [timerInterval]);

  const doPaswordResetRequest = async () => {
    const debugThis = false || passwordReset_debugThis;
    const msgPrefix = what + '.handlePasswordReset';

    try {
      const url = base_url + `/auth/requestPasswordReset`;

      if (debugThis) {
        console.log(msgPrefix, 'URL', url);
      }

      const body = {
        email: email,
        affiliation: affiliation,
      };

      const response = await axios.post(url, body);

      if (debugThis) {
        console.log(msgPrefix, 'response', JSON.stringify(response.data));
      }
    } catch (error) {
      console.error(msgPrefix, 'ERROR', error);
    }
  };

  const handleEmailChange = (value) => {
    setemail(value);
  };

  const handleAffiliationChange = (value) => {
    setAffiliation(value);
  };

  const navigateBack = () => {
    navigate('/');
  };

  return (
    <div className="forgot-password-form">
      <div className="forgot-password-container">
        <h1>Forgot your password?</h1>

        <div>
          <label>Enter your email address:</label>
          <input
            placeholder="email"
            className="forgot-password-input"
            type="text"
            value={email}
            onChange={(e) => handleEmailChange(e.target.value)}
          />
        </div>
        <div>
          <label>Enter the Big Fir Estates affiliation code:</label>
          <input
            placeholder="affiliation"
            className="forgot-password-input"
            type="text"
            value={affiliation}
            onChange={(e) => handleAffiliationChange(e.target.value)}
          />
        </div>
        <p>If the both are correct, a password reset email will be sent</p>
        <button disabled={submitButtonDisabled} onClick={handlePasswordReset}>
          Submit
        </button>
        <br />
        <br />
        <p>
          In an effort to thwart hackers, you will be redirected back to the home page in {countdown} seconds after
          clicking the submit button{' '}
        </p>

        {submitButtonDisabled && <p>Redirecting in {countdown} seconds...</p>}
      </div>
    </div>
  );
};

export default ForgotPassword;
