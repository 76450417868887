import './FiscalMonthDisplay.css';

const FiscalMonthDisplay = ({ month, year }) => {
  const getMonthName = (month) => {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    return months[month];
  };

  return (
    <div className="fiscal-month-display">
      ( fiscal year ends {getMonthName(month)} 1 {year} )
    </div>
  );
};

export default FiscalMonthDisplay;
