// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fiscal-month-display {
  font-size: 13px;
  margin-left: 10px;
}

/* Mobile Styles */
@media screen and (max-width: 600px) {
  .fiscal-month-display {
    margin-left: 5px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/common/FiscalMonthDisplay.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,iBAAiB;AACnB;;AAEA,kBAAkB;AAClB;EACE;IACE,gBAAgB;EAClB;AACF","sourcesContent":[".fiscal-month-display {\n  font-size: 13px;\n  margin-left: 10px;\n}\n\n/* Mobile Styles */\n@media screen and (max-width: 600px) {\n  .fiscal-month-display {\n    margin-left: 5px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
