import { useState } from 'react';

import { base_url } from '../../environment/url';
import axios from '../axiosApi/axiosApi';

import '../../global.css';
import './Disbursement.css';

const disbursement_debugThis = false;
const what = 'disbursement';

const Disbursement = ({ fundID, userID, onSubmit }) => {
  const [amount, setAmount] = useState('');
  const [amountError, setAmountError] = useState('');

  const [recipient, setRecipient] = useState('');
  const [recipientError, setRecipientError] = useState('');

  const [purpose, setPurpose] = useState('');
  const [purposeError, setPurposeError] = useState('');

  const [reference, setReference] = useState('');
  const [referenceError, setReferenceError] = useState('');

  const postDisbursement = async () => {
    const debugThis = false || disbursement_debugThis;
    const msgPrefix = what + '.postDisbursement';

    if (debugThis) {
      console.log(
        msgPrefix,
        `Processing disbursement ${amount} ${recipient} ${purpose} ${reference} for fund ${fundID} by user ${userID}`
      );
    }

    setAmountError('');
    setRecipientError('');
    setPurposeError('');
    setReferenceError('');

    try {
      const url = base_url + `/funds/${fundID}/disbursement`;

      const body = {
        fundManagerUserID: userID,
        recipient: recipient,
        amount: amount,
        purpose: purpose,
        reference: reference,
      };

      if (debugThis) {
        console.log(msgPrefix, 'URL', url);
      }

      const response = await axios.post(url, body);

      if (debugThis) {
        console.log(msgPrefix, 'response', JSON.stringify(response.data));
      }

      onSubmit();

      handleAmountChange('');
      handleRecipientChange('');
      handlePurposeChange('');
      handleReferenceChange('');
    } catch (error) {
      console.error(msgPrefix, 'Error', error);
      if (error.response.data.topic) {
        setAmountError(error.response.data.topic);
      }

      if (error.response.data.recipient) {
        setRecipientError('select a user');
      }

      if (error.response.data.amount) {
        setAmountError(error.response.data.amount);
      }

      if (error.response.data.purpose) {
        setPurposeError(error.response.data.purpose);
      }

      if (error.response.data.reference) {
        setReferenceError(error.response.data.reference);
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    postDisbursement();
  };

  const handleAmountChange = (value) => {
    setAmount(value);
    setAmountError('');
  };

  const handleRecipientChange = (value) => {
    setRecipient(value);
    setRecipientError('');
  };

  const handlePurposeChange = (value) => {
    setPurpose(value);
    setPurposeError('');
  };

  const handleReferenceChange = (value) => {
    setReference(value);
    setReferenceError('');
  };

  return (
    <div className="disbursement-container">

      <form onSubmit={handleSubmit}>
        <div className="disbursement-fields-row">
          <div className="disbursement-field">
            <label>Amount</label>
            {amountError && <div className="error-message">{amountError}</div>}
            <input type="text" value={amount} onChange={(e) => handleAmountChange(e.target.value)} required />
          </div>

          <div className="disbursement-field">
            <label>Recipient</label>
            {recipientError && <div className="error-message">{recipientError}</div>}
            <input type="text" value={recipient} onChange={(e) => handleRecipientChange(e.target.value)} required />
          </div>

          <div className="disbursement-field">
            <label>Purpose</label>
            {purposeError && <div className="error-message">{purposeError}</div>}
            <input type="text" value={purpose} onChange={(e) => handlePurposeChange(e.target.value)} required />
          </div>

          <div className="disbursement-field">
            <label>Reference</label>
            {referenceError && <div className="error-message">{referenceError}</div>}
            <input type="text" value={reference} onChange={(e) => handleReferenceChange(e.target.value)} required />
          </div>
        </div>

        <button>Submit</button>
      </form>
    </div>
  );
};

export default Disbursement;
