import React, { useState, useEffect, useCallback } from 'react';

import './FundManagerDisplay.css';

import { base_url } from '../../environment/url';
import axios from '../axiosApi/axiosApi';

const what = 'fundManagerDisplay';
const fundManagerDisplay_debugThis = false;

const FundManagersDisplay = ({ fundManagers }) => {
  if (!fundManagers) {
    return <></>;
  }
  const len = fundManagers.length;
  const label = len > 1 ? 'Fund Managers' : 'Fund Manager';

  return (
    <div className="fundsManagerContainer">
      {len > 0 ? (
        <>
          <h3>{label}</h3>
          <p>{fundManagers.map((manager) => `${manager.fName} ${manager.lName}`).join(', ')}</p>
        </>
      ) : (
        <p>No manager available</p>
      )}
    </div>
  );
};

export default FundManagersDisplay;
