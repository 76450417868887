import React, { useState } from 'react';

import '../../global.css'
import './plotInfo.css';

import { useAppContext } from '../context/appContextProvider';

const plotInfo_debugThis = false;
const what = 'plotInfo';

const PlotInfo = ({ onAddOwner, onDeleteOwner, plot }) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const { userID } = useAppContext();

  //console.log('plotinfo', JSON.stringify(plot));

  const getHeading = () => {
    if (plot !== null) {
      return <h1>Lot {plot.number}</h1>;
    }
    return <h1>Click on a lot</h1>;
  };

  const getTitle = () => {
    if (plot !== null && plot.owners && Array.isArray(plot.owners)) {
      if (plot.owners.length > 1) {
        return <h3>Owners</h3>;
      } else if (plot.owners.length === 1) {
        return <h3>Owner</h3>;
      }
    }
    
    return null;
  };

  const getOwners = () => {
    if (plot !== null && plot.owners && Array.isArray(plot.owners) && plot.owners.length !== 0) {
      // Map over the owners and return a <p> for each one
      return plot.owners.map((user, index) => <p key={index}>{`${user.fName} ${user.lName}`.trim()}</p>);
    }

    // Return a single <p> if there are no owners or plot is null
    return <p>{plot ? 'owner ' + plot.name : ''}</p>;
  };

  const addOwner = async () => {
    const debugThis = false || plotInfo_debugThis;
    const msgPrefix = what + '.addOwner';

    if (debugThis) {
      console.log(msgPrefix);
    }

    setIsButtonDisabled(true);
    await onAddOwner(plot._id);
    setIsButtonDisabled(false);
  };

  const delOwner = async () => {
    const debugThis = false || plotInfo_debugThis;
    const msgPrefix = what + '.delOwner';

    if (debugThis) {
      console.log(msgPrefix);
    }

    setIsButtonDisabled(true);
    await onDeleteOwner(plot._id);
    setIsButtonDisabled(false);
  };

  const getButtons = () => {
    if (!plot || !plot.owners || !Array.isArray(plot.owners)) {
      return null; // Return nothing if plot or owners data is invalid
    }

    const isOwner = plot.owners.some((user) => user._id === userID);

    return (
      <div>
        {!isOwner && (
          <button onClick={addOwner} disabled={isButtonDisabled}>
            Add me
          </button>
        )}
        {isOwner && (
          <button onClick={delOwner} disabled={isButtonDisabled}>
            Remove me
          </button>
        )}
      </div>
    );
  };

  return (
    <div>
      {getHeading()}
      <div className="plotInfo-button-container">{getButtons()}</div>

      <div className="plotInfo-owners-container">
        {getTitle()}
        <div className="plotInfo-owners-list">{getOwners()}</div>
      </div>
    </div>
  );
};

export default PlotInfo;
