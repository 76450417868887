import './FundsLedger.css';

const fundsLedger_debugThis = false;
const what = 'FundsLedger';

const FundsLedger = ({ fiscalYear, details }) => {
  const debugThis = false || fundsLedger_debugThis;
  const msgPrefix = what + '.entry_point';

  // Debugging props
  if (debugThis) {
    console.log(msgPrefix, 'Current Fiscal Year:', fiscalYear);
    console.log(msgPrefix, 'Details:', details);
  }

  const formatAmount = (value, val) => {
    const debugThis = false || fundsLedger_debugThis;
    const msgPrefix = what + '.formatAmount';

    let formatted = `$ ${value.toFixed(2)}`;
    const ret = val < 0 ? `(${formatted})` : formatted;

    if (debugThis) {
      console.log(msgPrefix, ret);
    }
    return ret;
  };

  const getBalance = (payments, disbursements) => {
    const debugThis = false || fundsLedger_debugThis;
    const msgPrefix = what + '.getBalance';

    const totalPayments = payments.reduce((sum, payment) => sum + payment.amount, 0);
    const totalDisbursements = disbursements.reduce((sum, disbursement) => sum + disbursement.amount, 0);
    const ret = totalPayments - totalDisbursements;

    if (debugThis) {
      console.log(msgPrefix, ret);
    }
    return ret;
  };

  const getFormattedBalance = (payments, disbursements) => {
    const debugThis = false || fundsLedger_debugThis;
    const msgPrefix = what + '.getBalance';

    const balance = getBalance(payments, disbursements);
    const ret = formatAmount(balance, balance);

    if (debugThis) {
      console.log(msgPrefix, ret);
    }
    return ret;
  };

  // Function to filter data by fiscal year
  const filterByFiscalYear = (entries) => {
    const debugThis = false || fundsLedger_debugThis;
    const msgPrefix = what + '.filterByFiscalYear';

    return entries.filter((entry) => {
      const entryYear = entry.fiscalYear;
      const ret = entryYear == fiscalYear;

      if (debugThis) {
        console.log(msgPrefix, 'Filtering Entry Date:', entry.date, 'Entry Year:', entryYear, 'fiscalYear', fiscalYear, 'ret', ret);
      }
      return ret;
    });
  };

  // Filter payments and disbursements based on fiscal year
  const filteredPayments = filterByFiscalYear(details.payments || []);
  const filteredDisbursements = filterByFiscalYear(details.disbursements || []);

  // Combine and sort entries
  const filteredEntries = [
    ...filteredPayments.map((payment) => ({ ...payment, type: 'Income' })),
    ...filteredDisbursements.map((disbursement) => ({ ...disbursement, type: 'Expense' })),
  ].sort((a, b) => new Date(a.date) - new Date(b.date));

  return (
    <div className="ledger-container">
      <h2 className="ledger-subtitle">Ledger Fiscal Year {fiscalYear}</h2>
      <table className="ledger-table">
        <thead>
          <tr className="ledger-table-header">
            <th className="ledger-table-header">Date</th>
            <th className="ledger-table-header">Type</th>
            <th className="ledger-table-header amount">Amount</th>
            <th className="ledger-table-header">Party</th>
            <th className="ledger-table-header">Description</th>
            <th className="ledger-table-header">Reference</th>
          </tr>
        </thead>
        <tbody className="ledger-table-body">
          {filteredEntries.length > 0 ? (
            filteredEntries.map((entry) => (
              <tr className="ledger-table-row" key={entry._id}>
                <td className="ledger-table-cell ledger-table-cell-date" data-label="Date">
                  {new Date(entry.date).toLocaleDateString()}
                </td>
                <td className="ledger-table-cell ledger-table-cell-type" data-label="Type">
                  {entry.type}
                </td>
                <td
                  className={`ledger-table-cell ledger-table-cell-amount ${
                    entry.type === 'Expense' ? 'negative-amount' : ''
                  }`}
                  data-label="Amount"
                >
                  {formatAmount(entry.amount, entry.type === 'Expense' ? -1 : 1)}
                </td>
                <td className="ledger-table-cell ledger-table-cell-party" data-label="Party">
                  {entry.recipient || entry.displayName}
                </td>
                <td className="ledger-table-cell ledger-table-cell-description" data-label="Description">
                  {entry.purpose || entry.method}
                </td>
                <td className="ledger-table-cell ledger-table-cell-reference" data-label="Reference">
                  {entry.reference}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6" className="ledger-table-cell ledger-table-cell-no-data">
                No data available for {fiscalYear}.
              </td>
            </tr>
          )}
        </tbody>
        {filteredEntries.length > 0 && (
          <tfoot>
            <tr className="ledger-table-footer">
              <td colSpan="2" className="ledger-table-footer-cell">
                Balance
              </td>
              <td
                className={`ledger-table-footer-cell amount ${
                  getBalance(filteredPayments, filteredDisbursements) < 0 ? 'negative-amount' : ''
                }`}
              >
                {getFormattedBalance(filteredPayments, filteredDisbursements)}
              </td>
              <td colSpan="3"></td>
            </tr>
          </tfoot>
        )}
      </table>
    </div>
  );
};

export default FundsLedger;
