// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.funds-detail-container {
  font-family: Arial, sans-serif;
  margin-top: 20px;
  margin-left: 20px;
}

.funds-detail-title {
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
}

.funds-detail-info {
  font-size: 16px;
  color: #666;
  margin: 5px 0;
}

.ledger-toggle {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.fiscalRow {
  display: flex;
  align-items: center;
}



/* Mobile Styles */
@media screen and (max-width: 600px) {
  .funds-detail-container {
    margin: 10px;
  }

  .funds-detail-title {
    font-size: 20px;
  }

  .funds-detail-info {
    font-size: 14px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/funds/FundsDetail.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,WAAW;EACX,aAAa;AACf;;AAEA;EACE,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;;;AAIA,kBAAkB;AAClB;EACE;IACE,YAAY;EACd;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,eAAe;EACjB;AACF","sourcesContent":[".funds-detail-container {\n  font-family: Arial, sans-serif;\n  margin-top: 20px;\n  margin-left: 20px;\n}\n\n.funds-detail-title {\n  font-size: 24px;\n  color: #333;\n  margin-bottom: 10px;\n}\n\n.funds-detail-info {\n  font-size: 16px;\n  color: #666;\n  margin: 5px 0;\n}\n\n.ledger-toggle {\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  margin-bottom: 10px;\n}\n\n.fiscalRow {\n  display: flex;\n  align-items: center;\n}\n\n\n\n/* Mobile Styles */\n@media screen and (max-width: 600px) {\n  .funds-detail-container {\n    margin: 10px;\n  }\n\n  .funds-detail-title {\n    font-size: 20px;\n  }\n\n  .funds-detail-info {\n    font-size: 14px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
