// App.js

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';
import { base_url } from './environment/url';

import { useAppContext } from './components/context/appContextProvider';

import Menu from './components/menu/Menu';
import LandingPage from './components/landing/LandingPage';
import LoginDialog from './components/login/LoginDialog';
import RegistrationDialog from './components/register/RegistrationDialog';
import Home from './components/home/Home';
import InteractiveMap from './components/plot/InteractiveMap';
import Funds from './components/funds/Funds';
import FundsDetail from './components/funds/FundsDetail';
import UserInfo from './components/userInfo/UserInfo';
import About from './components/about/about';

import PasswordReset from './components/passwordReset/PasswordReset';
import ForgotPassword from './components/forgotPassword/ForgotPassword';

import Voting from './components/voting/Voting';
import Suggestions from './components/suggestions/Suggestions';

import CCRs from './components/documents/ccrs/CCRs';
import WeedPlan from './components/documents/weedPlan/WeedPlan';
import DEQ from './components/documents/deq/Deq';

import './App.css';

const hours = 0;
const minutes = 15;
const seconds = 0;

// Convert hours, minutes, and seconds to milliseconds
const logoutTimeOut = (hours * 3600 + minutes * 60 + seconds) * 1000;
//console.log('timeout', logoutTimeOut);

const what = 'App';
const app_debugThis = false;

const App = () => {
  const { setUserFName, userID, setUserID, setAffiliation } = useAppContext();

  const [allowRegistration, setAllowRegistration] = useState(false);
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [loginError, setLoginError] = useState(null);
  const [logoutTimeoutId, setLogoutTimeoutId] = useState(null);

  const [showRegistration, setShowRegistration] = useState(false);
  const [registerError, setRegisterError] = useState(null);

  const modalContentRef = useRef(null);

  useEffect(() => {
    const fetchSystemData = async () => {
      const debugThis = false || app_debugThis;
      const msgPrefix = what + '.fetchSystemData';

      try {
        const url = base_url + '/system/info';

        if (debugThis) {
          console.log(msgPrefix, 'URL', url);
        }

        const response = await axios.get(url);

        if (debugThis) {
          console.log(msgPrefix, 'response', JSON.stringify(response.data));
        }

        setAllowRegistration(response.data.showRegistration);
      } catch (error) {
        console.error(msgPrefix, 'Error', error);
      }
    };

    // Call the function when the component mounts
    fetchSystemData();
  }, []); // Empty dependency array means this effect runs once on mount

  const clearTimer = useCallback(
    (caller, caller_debugThis) => {
      const debugThis = false || caller_debugThis || app_debugThis;
      const msgPrefix = what + caller + '.clearTimer';

      // Clear the previous timeout
      if (logoutTimeoutId) {
        if (debugThis) {
          console.log(msgPrefix, 'clearing timer');
        }

        clearTimeout(logoutTimeoutId);
        setLogoutTimeoutId(null);
      }
    },
    [logoutTimeoutId, setLogoutTimeoutId]
  );

  const handleLogout = useCallback(async () => {
    const debugThis = false || app_debugThis;
    const msgPrefix = what + '.handleLogout';

    if (userID !== '') {
      let body = {
        userID: userID,
      };

      const url = base_url + '/auth/logout';
      if (debugThis) {
        console.log(msgPrefix, 'URL', url);
      }

      const response = await axios.post(url, body);

      if (debugThis) {
        console.log(msgPrefix, 'response', JSON.stringify(response));
      }

      sessionStorage.removeItem('userID');
      sessionStorage.removeItem('affiliation');
      sessionStorage.removeItem('fName');

      sessionStorage.removeItem('jwt');
      sessionStorage.removeItem('refreshToken');
      sessionStorage.removeItem('timeoutValue');

      // clear the context
      setLoggedIn(false);
      setUserID('');
      setAffiliation('');
      setUserFName('');

      const root = window.location.origin + '\\' + window.location.search;
      window.location.href = root;
    }

    // Clear the timeout when the user logs out manually
    clearTimer(msgPrefix, debugThis);
  }, [userID, setLoggedIn, setUserID, setUserFName, setAffiliation, clearTimer]);

  const resetLogoutTimer = useCallback(() => {
    const debugThis = false || app_debugThis;
    const msgPrefix = what + '.resetLogoutTimer';

    const timeoutValue = sessionStorage.getItem('timeoutValue');
    if (!timeoutValue) {
      console.log(msgPrefix, 'THROWING');
      throw new Error('unable to find timeout value');
    }

    // Clear the previous timeout
    clearTimer('resetLogoutTimer');

    // Set a new timeout based on the timeoutValue
    const newTimeoutId = setTimeout(() => {
      if (debugThis) {
        console.log(msgPrefix, 'timer expired');
      }

      handleLogout();
    }, timeoutValue);

    // Store the new timeout ID in the state
    if (debugThis) {
      console.log(msgPrefix, 'setting timer', timeoutValue.toString());
    }

    setLogoutTimeoutId(newTimeoutId);
  }, [handleLogout, clearTimer, setLogoutTimeoutId]);

  useEffect(() => {
    const setUserInfo = async () => {
      const debugThis = false || app_debugThis;
      const msgPrefix = what + '.setUserInfo';

      const jwt = sessionStorage.getItem('jwt');
      const refreshToken = sessionStorage.getItem('refreshToken');

      if (jwt && refreshToken) {
        if (debugThis) {
          console.log(msgPrefix, 'logged in');
        }
        setLoggedIn(true);

        // Fetch the user ID from sessionStorage
        const storedUserID = sessionStorage.getItem('userID');
        const affiliation = sessionStorage.getItem('affiliation');
        const fName = sessionStorage.getItem('fName');

        // use the context
        setUserID(storedUserID);
        setAffiliation(affiliation);
        setUserFName(fName);

        // Set timeout for automatic logout using the timeoutValue from sessionStorage
        resetLogoutTimer();
      }
    };

    setUserInfo();
  }, [setUserID, setAffiliation, setUserFName]);

  const showRegistrationDialog = () => {
    setRegisterError(null);
    setShowRegistration(true);
  };

  const hideRegistrationDialog = () => {
    setRegisterError(null);
    setShowRegistration(false);
  };

  const handleRegistration = async () => {
    hideRegistrationDialog();
    showLoginDialog();
  };

  const showLoginDialog = () => {
    setLoginError(null);
    setShowLogin(true);
  };

  const hideLoginDialog = () => {
    setLoginError(null);
    setShowLogin(false);
  };

  const getUserDataFromToken = (jwt) => {
    const userInfo = jwtDecode(jwt);
    const user = userInfo.docs[0];
    console.log('getUserDataFromToken', JSON.stringify(user));

    return user;
  };

  const handleLogin = async ({ email, password }) => {
    const debugThis = false || app_debugThis;
    const msgPrefix = what + '.handleLogin';

    try {
      const url = base_url + '/auth/login';

      if (debugThis) {
        console.log(msgPrefix, 'URL', url);
      }

      const response = await axios.post(url, { email, password });

      if (debugThis) {
        console.log(msgPrefix, 'response', JSON.stringify(response.data));
      }

      var timeoutValue = logoutTimeOut;
      const { tokens } = response.data;

      if (debugThis) {
        console.log(msgPrefix, 'Timeout ', timeoutValue.toString());
      }
      // Save sessionStorage information
      sessionStorage.setItem('timeoutValue', timeoutValue);
      sessionStorage.setItem('jwt', tokens.token);
      sessionStorage.setItem('refreshToken', tokens.refreshToken);

      const userData = getUserDataFromToken(tokens.token);

      sessionStorage.setItem('userID', userData._id);
      sessionStorage.setItem('affiliation', userData.REF_Affiliation_Current);
      sessionStorage.setItem('fName', userData.fName);

      // Update the app context

      setUserID(userData._id);
      setAffiliation(userData.REF_Affiliation_Current);
      setUserFName(userData.fName);

      setLoggedIn(true);

      hideLoginDialog();

      // Clear the previous timeout and set a new one
      resetLogoutTimer();
    } catch (error) {
      console.error(msgPrefix, 'ERROR', error.message);
      // Handle login error
      setLoginError('login failed, check email and password');
    }
  };

  return (
    <Router>
      {isLoggedIn && <Menu onLogout={handleLogout} />}
      <div className="App-container">
        <Routes>
          <Route
            path="/"
            element={
              isLoggedIn ? (
                <Navigate to="/home" />
              ) : (
                <LandingPage
                  allowRegistration={allowRegistration}
                  showLoginDialog={showLoginDialog}
                  showRegistrationDialog={showRegistrationDialog}
                />
              )
            }
          />
          <Route path="/home" element={isLoggedIn ? <Home /> : <Navigate to="/" />} />

          <Route path="/map" element={isLoggedIn && <InteractiveMap />} />

          <Route path="/funds" element={isLoggedIn && <Funds />} />
          <Route path="/fund/:id/:name" element={isLoggedIn && <FundsDetail />} />

          <Route path="/ccrs" element={isLoggedIn && <CCRs />} />
          <Route path="/weedplan" element={isLoggedIn && <WeedPlan />} />
          <Route path="/deq" element={isLoggedIn && <DEQ />} />

          <Route path="/voting" element={isLoggedIn && <Voting />} />
          <Route path="/suggestionBox" element={isLoggedIn && <Suggestions />} />

          <Route path="/userInfo" element={isLoggedIn && <UserInfo />} />

          <Route path="/about" element={isLoggedIn && <About />} />

          <Route path="/passwordReset/:affiliation/:id" element={<PasswordReset />} />
          <Route path="/forgotPassword" element={<ForgotPassword />} />
        </Routes>

        {showRegistration && (
          <RegistrationDialog
            onRegistration={handleRegistration}
            onCancel={hideRegistrationDialog}
            modalContentRef={modalContentRef}
            registerError={registerError}
          />
        )}

        {showLogin && (
          <LoginDialog
            onLogin={handleLogin}
            onCancel={hideLoginDialog}
            modalContentRef={modalContentRef}
            loginError={loginError}
          />
        )}
      </div>
    </Router>
  );
};

export default App;
