import { useState } from 'react';

import './FiscalCloseYearButton.css';

const fiscalCloseYearButton_debugThis = false;
const what = 'fiscalCloseYearButton';

const FiscalCloseYearButton = ({ fiscalYear, details, closeFiscalYear }) => {
  const [submitButtonVisible, setSubmitButtonVisible] = useState(true);

  const canCloseFiscalYear = () => {
    const debugThis = false || fiscalCloseYearButton_debugThis;
    const msgPrefix = what + '.canCloseFiscalYear';
    let selFiscalYear = parseInt(fiscalYear);

    let curFiscalYearStart = parseInt(details.fiscalYearStart);
    let curFiscalYearEnd = parseInt(details.fiscalYearEnd);

    if (debugThis) {
      console.log(
        msgPrefix,
        'curFiscalYearStart',
        curFiscalYearStart,
        'selected fiscal year',
        selFiscalYear,
        'curFiscalYearEnd',
        curFiscalYearEnd
      );
    }

    //                     2023                  2023             2024                2023 + 1
    const isInFiscalYear = curFiscalYearStart == selFiscalYear && curFiscalYearEnd == selFiscalYear + 1;

    let isInFiscalMonth = false;
    if (isInFiscalYear) {
      const now = new Date();
      const curYear = now.getFullYear();

      if (curYear == curFiscalYearEnd) {
        const curMonth = now.getMonth();
        const curFiscalMonth = parseInt(details.fiscalMonth);
        if (debugThis) {
          console.log(msgPrefix, 'curMonth', curMonth, 'curFiscalMonth', curFiscalMonth);
        }

        if (curMonth >= curFiscalMonth) {
          const curDate = now.getDate();
          const curFiscalDate = parseInt(details.fiscalDate);

          if (debugThis) {
            console.log(msgPrefix, 'curDate', curDate, 'curFiscalDate', curFiscalDate);
          }

          if (curDate > curFiscalDate) {
            isInFiscalMonth = true;
          }
        }
      }
    }

    let retval = isInFiscalYear && isInFiscalMonth;

    if (debugThis) {
      console.log(msgPrefix, 'retval', retval);
    }

    return retval;
  };

  const handleCloseYearClick = () => {
    const debugThis = false || fiscalCloseYearButton_debugThis;
    const msgPrefix = what + '.handleCloseYearClick';
    if (debugThis) {
      console.log(msgPrefix, details);
    }

    closeFiscalYear();

    setSubmitButtonVisible(false);
  };

  return (
    <div className="fiscalCloseYearButton">
      {canCloseFiscalYear() && submitButtonVisible && <button onClick={handleCloseYearClick}>Close Year</button>}
    </div>
  );
};

export default FiscalCloseYearButton;
