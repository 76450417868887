import { useState, useEffect } from 'react';

import { base_url } from '../../environment/url';
import axios from '../axiosApi/axiosApi';

import './Suggestions.css';

import SuggestionList from './SuggestionList';
import NewSuggestion from './NewSuggestion';

const suggestions_debugThis = false;
const what = 'suggestions';

const Suggestions = () => {
  const [suggestions, setSuggestions] = useState([]);

  const fetchData = async () => {
    const debugThis = false || suggestions_debugThis;
    const msgPrefix = what + '.fetchData';

    try {
      const url = base_url + '/suggestion';

      if (debugThis) {
        console.log(msgPrefix, 'URL', url);
      }

      const response = await axios.get(url);

      if (debugThis) {
        console.log(msgPrefix, 'response', JSON.stringify(response.data));
      }

      setSuggestions(response.data);
    } catch (error) {
      console.error(msgPrefix, 'Error', error);
    }
  };

  useEffect(() => {
    // Call the function when the component mounts
    fetchData();
  }, []);

  const reloadSuggestions = async () => {
    const debugThis = false || suggestions_debugThis;
    const msgPrefix = what + '.reloadSuggestions';
    if (debugThis) {
      console.log(msgPrefix);
    }
    fetchData();
  };

  return (
    <div className="suggestion-container">
      <h1>Suggestion Box</h1>
      <div className="suggestion-blurb">
        <p>This is the place to make your wants known</p>
        <p>OR if you find something that is not working right... let me know</p>
      </div>

      <NewSuggestion
        onSubmit={reloadSuggestions}
        subject={'Suggestion'}
        topicLabel={'Topic'}
        descriptionLabel={'Description'}
        urlAddress="suggestion"
      />
      <SuggestionList suggestions={suggestions} onRefreshList={reloadSuggestions} />

      <div className="suggestion-notes">
        <h4>If you created the suggestion, you can:</h4>
        <p>Delete it</p>
        <p>Turn commenting on and off for it</p>
      </div>
    </div>
  );
};

export default Suggestions;
