// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.name_group {
  display: flex;
  flex-direction: row; /* Horizontal layout */
  align-items: center; /* Vertical alignment */
  gap: 10px; 
  width: 100%; 
  white-space: nowrap; /* Prevents wrapping */

  /* border: 1px solid red; */
}
`, "",{"version":3,"sources":["webpack://./src/components/funds/FundsTable.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB,EAAE,sBAAsB;EAC3C,mBAAmB,EAAE,uBAAuB;EAC5C,SAAS;EACT,WAAW;EACX,mBAAmB,EAAE,sBAAsB;;EAE3C,2BAA2B;AAC7B","sourcesContent":[".name_group {\n  display: flex;\n  flex-direction: row; /* Horizontal layout */\n  align-items: center; /* Vertical alignment */\n  gap: 10px; \n  width: 100%; \n  white-space: nowrap; /* Prevents wrapping */\n\n  /* border: 1px solid red; */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
