import React, { createContext, useContext, useState } from 'react';

const AppContext = createContext();

export const AppContextProvider = ({ children }) => {
  const [userFName, setUserFName] = useState('');
  const [userID, setUserID] = useState('');
  const [affiliation, setAffiliation] = useState('');

  const value = {
    userFName,
    setUserFName,
    userID,
    setUserID,
    affiliation,
    setAffiliation,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export const useAppContext = () => useContext(AppContext);
